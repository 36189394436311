@use '../../styles/variables.scss' as *;
@use '../../styles/mixins.scss' as *;

@function getImageWidth($h) {
    @return calc(($h / 8) * 15);
}

$header-row-height: 150px;
.container {
    width: 100%;
    color: #6a6a63;
    display: grid;
    grid-auto-rows: auto;

    @include media-mobile() {
        grid-template-columns: auto;
        row-gap: 20px;
    }

    @include media-tablet() {
        grid-template-columns: auto;
        row-gap: 30px;
    }

    @include media-desktop() {
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 80px;
        height: 100%;
        min-width: 1460px;
        justify-items: center;
    }

    .container-header {
        display: flex;
        justify-content: center;
        grid-row: 1;
        grid-column: 1/4;
        width: 100%;
        height: $header-row-height;

        @include media-mobile() {
            grid-column: 1;
            height: auto;
        }

        @include media-tablet() {
            grid-column: 1;
            height: auto;
        }

        .container-content-steps {
            width: auto;
            align-self: center;
        }
    }

    .container-preview {
        $border-radius: 15px;
        //overflow: hidden;

        > * {
            &:first-child {
                overflow: hidden;
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
            }

            &:last-child {
                overflow: hidden;
                border-bottom-left-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
            }
        }

        @include media-mobile() {
            grid-row: 2;
            grid-column: 1;
            
            display: flex;
            flex-direction: column;
                        
            > * {
                $h: 100px;
                $w: getImageWidth($h);
                height: $h;
                width: $w;

                margin: 0 auto;
            }
        }

        @include media-tablet() {
            grid-row: 2;
            grid-column: 1;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            row-gap: 30px;

            > * {
                $h: 150px;
                $w: getImageWidth($h);
                height: $h;
                width: $w;
                margin: 0 auto;

                &:first-child {
                    margin: 0 calc((100% - $w) / 2);
                }
            }
        }

        @include media-desktop() {
            grid-row: 2;
            grid-column: 1;
            display: flex;
            flex-direction: column;
            margin-top: -$header-row-height;
            
            img {
                width: calc((250px / 8) * 15);
            }
        }
    }

    .container-customer-info,
    .container-pricing {
        grid-row: 2;
    }

    .container-customer-info {
        grid-column: 2;

        @include media-mobile() {
            grid-column: 1;
            grid-row: 3;
        }

        @include media-tablet() {
            grid-column: 1;
            grid-row: 3;
        }

        @include media-desktop() {
            > * {
                width: 380px;
            }
        }
    }

    .container-pricing {
        grid-column: 3;

        @include media-mobile() {
            grid-column: 1;
            grid-row: 4;
        }

        @include media-tablet() {
            grid-column: 1;
            grid-row: 4;
        }

        @include media-desktop() {
            margin-top: -$header-row-height;
            
            > * {
                width: 460px;
            }
        }

        .pricing-configuration {
            > div {
                padding-bottom: 60px;
            }
        }

        .pricing-summary {
            margin-top: -40px;
            z-index: 1;
        }
    }

    .container-checkout {
        grid-column: 1;
    }

    &.container-no-preview {
        grid-template-columns: 100%;

        .container-header {
            grid-column: 1;
        }

        .container-preview {
            display: none;
        }
    }
}
