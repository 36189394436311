@use '../../styles/variables.scss' as *;
@use '../../styles/mixins.scss' as *;

@mixin font-size() {
    font-size: $form-font-size-sm + px;
    @include media-mobile() {
        font-size: ($form-font-size-sm - 2) + px;
    }
}
.pricing-modal-container {
    width: 100%;
    @media screen {
        max-width: 500px;    
    }
    @media print {
        width: 6.5in;
    }
    
    font-family: $font-family-roboto;
    font-weight: 400;

    .title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        color: $branding-color;
        padding: 0 25px;
        font-weight: 500;
        
        line-height: 130%;
        @include font-size();

        height: 20px;
        :global {
            .ant-btn {
                padding: 0;
                @include font-size();
                border: none;
                text-transform: capitalize;
                height: 100%;
            }
        }
    }

    .title-divider {
        padding: 0 25px;
    }

    .title-divider,
    .divider {
        margin: 10px 0;
    }

    .images {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-bottom: 15px;

        > img {
            max-height: 250px;
            width: 50%;
        }
    }

    .pricing-container {
        @include font-size();
        color: $text-color;

        :global {
            .pricing-row {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                padding: 3px 25px;

                column-gap: 40px;

                &-bold {
                    font-weight: bold;
                }

                .pricing-row-value {
                    white-space: nowrap;
                }

                &:nth-child(even) {
                    background-color: #f9f9f9;
                }
            }

            .pricing-row-divider {
                margin: 10px 0;
                border-color: black;
            }
        }
    }

    .pricing-warning {
        padding: 0 25px;
        color: $branding-color;
        font-size: ($form-font-size-sm - 1) + px;
        @include media-mobile() {
            font-size: ($form-font-size-sm - 3) + px;
        }
    }
}

@media print {
    body * {
        visibility: hidden;
    }

    .pricing-modal-container,
    .pricing-modal-container * {
        visibility: visible;

        button,
        button * {
            visibility: hidden;
        }
    }

    .pricing-container {
        page-break-inside: auto;

        > * {
            page-break-inside: avoid;
            page-break-after: auto;
        }
    }
    @page {
        size: letter;
    }
}