@use '../variables.scss' as *;
@use 'sass:math';

$offset: 4;
$size: calc($switch-height - $offset) + px;
$size-sm: calc($switch-height-sm - $offset) + px;

.ant-switch {
    background-color: $switch-off-color;

    height: $switch-height + px;
    &.ant-switch-checked {
        .ant-switch-handle {
            inset-inline-start: calc(100% - 22px);
        }
    }

    .ant-switch-handle {
        width: $size;
        height: $size;
    }
}

.ant-switch {
    &.ant-switch-small {
        height: $switch-height-sm + px !important;
        line-height: $switch-height-sm + px !important;
        min-width: 44px;
        
        &.ant-switch-checked {
            .ant-switch-handle {
                inset-inline-start: calc(100% - 22px);
            }
        }

        .ant-switch-handle {
            width: $size-sm !important;
            height: $size-sm !important;
        }
    }
}

.ant-form-item-has-error {
    .ant-switch {
        &.ant-switch-checked {
            background: $form-error-color;
        }
    }
}

// .ant-form-item {
//     &.direction-horizontal {
//         .ant-form-item-control {
//             flex: 1 0 0;
//         }
//     }
// }