@use '../../../styles/variables.scss' as *;

.discounts-row {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    white-space: normal;
    
    :global {
        .ant-form-item:not(.ant-form-item-hidden) {
            display: flex;
            flex: 1 1 100%;
            align-items: center;

            .ant-row {
                //width: 100%;

                .ant-col {
                    
                }
            }
        }
    }
}