@use '../../styles/variables.scss' as *;
@use '../../styles/mixins.scss' as *;

.payments-block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    // column-gap: 20px;
    // row-gap: 10px;
    // grid-auto-flow: row;

    .payments-block-row {
        display: flex;
        flex-direction: row;
        gap: 20px;
        flex: 0 1 100%;
        overflow: hidden;
        @include media-mobile() {
            gap: 0;
        }

        .payments-block-item {
            display: flex;
            flex-grow: 0;
            flex-shrink: 0;
            font-family: $font-family-roboto;
            font-weight: 400;
            font-size: 14px;
            color: $text-color;

            &:last-child {
                white-space: break-spaces;
                flex-shrink: 1;
            }

            @include media-mobile() {
                &:first-child {
                    width: 80px;
                }

                &:nth-child(2) {
                    width: 70px;
                }

                &:nth-child(3) {
                    width: 80px;
                }
                
                overflow: hidden;
                text-overflow: ellipsis;
            }

            @include media-tablet() {
                &:first-child {
                    width: 80px;
                }

                &:nth-child(2) {
                    width: 70px;
                }

                &:nth-child(3) {
                    width: 80px;
                }
            }

            @include media-desktop() {
                &:first-child {
                    width: 102px;
                }

                &:nth-child(2) {
                    width: 70px;
                }

                &:nth-child(3) {
                    width: 80px;
                }
            }

            &-header {
                font-weight: 500;
                font-size: 16px;
                color: $branding-color;

                @include media-mobile() {
                    white-space: normal;
                }

                @include media-tablet() {
                    white-space: normal;
                }
            }
        }
    }
}
