* {
    box-sizing: border-box;
}

html,
body {
}

body {
    table,
    td,
    th {
        border: none;
    }
}

.ant-collapse {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
}

iframe {
    position: relative;
}

@for $i from 1 through 10 {
  .flex-order-#{$i} {
    order: $i;
  }
}
