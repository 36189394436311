@use '../../styles/variables.scss' as *;

$font-size: 14px;
$font-weight: 400;
$font-weight-bold: 500;
$line-height: 130%;
$font-family: $font-family-roboto;

@mixin row-horizontal {
    .pricing-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
    }
}

.pricing-rows {
    .pricing-row {
        font-weight: $font-weight;
        font-family: $font-family-roboto;
        line-height: $line-height;
        font-size: $font-size;
        
        &-bold {
            font-weight: $font-weight-bold;
        }

        .pricing-row-label {
            color: $text-color;
        }

        .pricing-row-value {
            color: $branding-color;
            white-space: nowrap;
        }
    }

    &-single-column {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @include row-horizontal;
    }

    &-multi-column {
        display: grid;
        grid-template-columns: repeat(auto-fill, calc(50% - 20px));
        grid-template-rows: 1fr auto;
        column-gap: 40px;
        row-gap: 10px;

        @include row-horizontal;
    }

    &-single-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 20px;
    }
}