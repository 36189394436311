@use '../../styles/variables.scss' as *;
@use '../../styles/mixins.scss' as *;

.container {
    display: grid;
    grid-template-columns: 50%;
    grid-auto-rows: auto;
    min-width: 100%;
    row-gap: 40px;

    @include media-mobile() {
        grid-template-columns: 100%;
    }

    @include media-tablet() {
        grid-template-columns: 250px 1fr;
        column-gap: 40px;
        justify-items: center;
    }
    
    @include media-desktop() {
        justify-content: center;
        column-gap: 80px;
        grid-template-columns: auto;
        min-height: 750px;
    }

    .pricing {
        @include media-mobile() {
            width: 100%;
            grid-row: 2;
            grid-column: 1;
        }

        @include media-tablet() {
            width: 100%;
            grid-row: 2;
            grid-column: 1;
        }

        @include media-desktop() {
            width: 380px;
            grid-row: 1;
            grid-column: 1;
        }
    }

    .payments {
        @include media-mobile() {
            width: 100%;
            grid-row: 3;
            grid-column: 1;
        }

        @include media-tablet() {
            width: 100%;
            grid-row: 2;
            grid-column: 2;
        }

        @include media-desktop() {
            width: 600px;
            grid-row: 2;
            grid-column: 1;
        }
    }

    .make-payment {
        .payment-type {
            :global(label) {
                height: auto;
                font-weight: 500;
            }

            @include media-mobile() {
                :global .ant-form-item-label {
                    text-align: center;
                }

                :global .ant-form-item-control {
                    align-items: center;
                    label {
                        font-weight: 400;
                    }
                }
            }

            @include media-tablet() {
                :global .ant-form-item-label {
                    text-align: center;
                }

                :global .ant-form-item-control {
                    align-items: center;
                    label {
                        font-weight: 400;
                    }
                }
            }
        }

        :global(.ant-input-password-icon) {
            color: $branding-color;
        }

        :global {
            .exp-date {
                .ant-form-item-control {
                    
                    
                    input {
                        width: 85px;
                        text-align: center;
                    }
                }
            }
        }

        @include media-mobile() {
            grid-row: 1;
            grid-column: 1;

            :global button[type='submit'] {
                display: block;
                margin: 0 auto;
            }
        }

        @include media-tablet() {
            :global button[type='submit'] {
                display: block;
                margin: 0 auto;
            }

            grid-row: 1;
            grid-column: 1/3;
        }

        @include media-desktop() {
            width: 308px;
            grid-row: 1/3;
            grid-column: 2;
        }
    }
}
