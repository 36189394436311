@use '../../styles/variables.scss' as *;

.payment-status-modal {
    padding: 20px !important;
}

.payment-status-modal-content {
    .title {
        font-size: 20px;
        font-family: 'Roboto';
        text-align: center;
        margin-bottom: 8px;
    }

    &.error {
        .title {
            color: $form-error-color;
        }
    }

    &.success {
        .title {
            color: $branding-color;
        }
    }
    
    pre {
        font-family: $form-font-family;
        font-size: $form-font-size + px;
        color: $form-text-color;
    }

    button {
        float: right;
    }
}