@use '../../styles/variables.scss' as *;

.content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 327px;

    .warning {
        font-family: $font-family-roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        text-align: center;
        margin-bottom: 25px;
        color: $branding-color;
    }

    .info {
        font-family: $font-family-roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        text-align: center;
        margin-bottom: 25px;
        color: $text-color;
    }

    .buttons {
        text-align: center;
    }
}