.configurator-container {
    width: 100%;
    height: 100%;
}
.pricing-modal {
    padding-top: 55px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 10px !important;
    overflow: hidden;
}