@import '../../styles/variables';

.product-container {
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    
    &:hover {
        transition: .3s;
        opacity: 0.8;
    }
    
    .product {
        .image {
            img {
                width: 100%;
                height: 208px;
                object-fit: cover;
            }
        }
        
        .title {
            height: 40px;
            width: 100%;
            font-size: 24px;
            font-weight: 700;
            text-align: center;
            background-color: #748b89;
            color: white;
            font-family: $font-family-futura;
        }
    }
}