@use '../../styles/variables.scss' as *;
@use '../../styles/mixins.scss' as *;

.map-field-container {
    position: relative;
}

.map-btn {
    height: 24px;
    width: 24px !important;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    border: none !important;
}

.billing-info {
    display: flex;
    flex: 0 0 50%;
    justify-content: center;

    @include media-mobile() {
        :global .ant-row {
            justify-content: center;
        }
    }
    @include media-tablet() {
        :global .ant-row {
            justify-content: center;

            .ant-form-item-label {
                padding: 0;
                display: flex;
            }
        }
    }
}