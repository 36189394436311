@use '../variables' as *;

.ant-select {
    &:not(.ant-select-focused):hover {
        .ant-select-selection-placeholder {
            color: $hover-color;
        }
    }

    &.ant-select-focused {
        .ant-select-selector {
            color: $form-input-color;
            border-color: $form-input-color !important;
        }
    }

    &.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
        inset-inline-start: 7px;
    }
}