@use '../variables' as *;
@use '../mixins.scss' as *;

form:not(.pure),
.threekit-form-wrapper form {
    .ant-form-item-label {
        display: inline-flex;
        align-items: center;

        > label {
            display: inline-flex;
            color: inherit;
            font-family: $form-font-family;
            font-size: $form-font-size + px;
            font-weight: 400;
            height: auto;
            @include media-mobile() {
                font-size: $form-font-size-sm + px;
            }

            &.ant-form-item-required {
                &::before {
                    display: none !important;
                    content: '' !important;
                }
                >label >span::after {
                    display: inline-block;
                    margin-inline-end: 0;
                    font-size: 16px;
                    color: $form-required-color;
                    font-family: $font-family-roboto;
                    font-weight: 400;
                    /* line-height: 1; */
                    content: "*";
                    margin-inline-start: 2px;
                }
                // &:not(.ant-form-item-required-mark-optional)::before {
                //     order: 2;
                // }
            }
        }
    }
    .ant-input-number-affix-wrapper {
        width: 100%;
        padding-right: 2px;
        overflow: hidden;

        .ant-input-number {
            overflow: hidden;

            .ant-input-number-handler-wrap {
                .ant-input-number-handler {
                    border-left: 0;
                }
            }
        }

        &.ant-input-number-affix-wrapper-has-feedback {
            .ant-input-number-handler-wrap {
                display: none;
            }
        }
    }
}

$statuses: 'success', 'warning', 'error', 'validating';

.ant-form-item {
    margin-bottom: 12px;

    &.custom-form-item-error {
        display: flex;
        //margin-top: -12px;
        margin-bottom: 5px;

        transition: none !important;

        * {
            transition: none !important;
        }

        .ant-form-item-margin-offset {
            display: none !important;
            margin: 0 !important;
        }

        .ant-form-item-explain.ant-form-item-explain-connected + div {
            display: none !important;
            margin: 0 !important;
        }
    }

    &.custom-form-item-info,
    &.custom-form-item-error {
        .ant-form-item-control-input {
            display: none;
        }
    }

    @each $s in $statuses {
        &.hide-status-#{$s} {
            .ant-form-item-feedback-icon.ant-form-item-feedback-icon-#{$s} {
                display: none;
            }
        }
    }

    &.hide-errors {
        .ant-form-item-control-input + div {
            display: none !important;
        }

        .ant-form-item-margin-offset {
            display: none;
        }
    }

    .ant-form-item-control {
        //line-height: $form-input-line-height + px;

        .ant-form-item-control-input {
            //min-height: auto;
        }

        .ant-form-item-explain-error {
            font-family: $font-family-roboto;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
        }

        .ant-form-item-feedback-icon {
            font-size: $form-input-font-size + px;
        }
    }

    &.direction-horizontal {
        .ant-row {
            position: relative;
            display: flex;
            flex-direction: row;
            flex: 0 0 100%;

            .ant-col {
                position: initial;

                &.ant-form-item-control {
                    .ant-form-item-control-input {
                        .ant-form-item-control-input-content {
                            display: flex;
                            justify-content: flex-end;
                            flex: 1 0 100%;

                            .ant-input-suffix {
                                height: $form-input-line-height;
                            }
                        }
                    }

                    > .ant-form-item-extra {
                    }
                }

                @include media-mobile() {
                    padding: 0;
                }
            }
        }
    }

    &.direction-vertical {
        .ant-row {
            position: relative;
            display: flex;
            flex-direction: column;
            flex: 1 1 100%;

            .ant-form-item-label {
                width: 100%;
            }

            .ant-form-item-control {
                .ant-radio-group {
                    display: flex;
                    flex-direction: column;
                }

                .ant-form-item-explain-error {
                    position: absolute;
                }
            }
        }
    }
}

.ant-form {
    &.ant-form-horizontal {
        .ant-form-item {
            &:not(.ant-form-item-direction-horizontal),
            &:not(.ant-form-item-direction-vertical) {
                .ant-form-item-label {
                    //line-height: $form-label-line-height + px;
                    min-height: $form-input-height + px;
                    @include media-mobile() {
                        //line-height: $form-label-line-height-sm + px;
                        min-height: $switch-height-sm + px;
                    }
                    @include media-tablet() {
                        //line-height: $form-label-line-height-sm + px;
                        min-height: $form-input-height-sm + px;
                    }
                    label {
                        //height: 100%;
                        min-height: $switch-height + px;
                        //max-height: $form-input-height + px;
                        @include media-mobile() {
                            min-height: $switch-height-sm + px;
                            //max-height: $form-input-height-sm + px;;
                        }
                        @include media-tablet() {
                            //line-height: $form-label-line-height-sm + px;
                            min-height: $switch-height-sm + px;
                        }
                    }
                }
            }

            @include media-mobile() {
                .ant-row {
                    .ant-col {
                        max-width: initial;
                        flex: 1;
                    }

                    .ant-col-6 {
                        max-width: 25%;
                    }

                    .ant-col-12 {
                        max-width: 50%;
                    }

                    .ant-col-24 {
                        max-width: 100%;
                    }
                }
            }
        }
    }
    .ant-form-header {
        margin-left: -60px;
        @include media-mobile() {
            margin-left: -40px;
        }
        color: $error-color;
        font-weight: 450;
    }
}

.antd-custom-form-item-info,
.antd-custom-form-item-error-container {
    font-size: ($form-input-font-size - 1) + px;
    margin-top: -6px;
    margin-bottom: 6px;
    line-height: ($form-input-font-size + 5) + px;

    @include media-mobile() {
        font-size: ($form-input-font-size-sm - 1) + px;
        line-height: ($form-input-font-size-sm + 5) + px;
    }
}

.antd-custom-form-item-info {
    
}

.antd-custom-form-item-error-container {
    color: $form-error-color;
    white-space: normal;
}

.ant-form-item-only-error {
    .ant-form-item-control-input {
        display: none;
        // min-height: 1px;
        // height: 1px;
    }
    margin-bottom: 0px;
}

.ant-form-item-control {
    .ant-space-compact {
        .ant-form-item {
            margin-bottom: 0;
        }
    }
}