@use '../../../styles/variables.scss' as *;

.discount-label {
    color: $form-label-color;
    display: flex;

    .discount-label-text {
        word-wrap: normal;
        white-space: normal;
    }

    .discount-remove-btn {
        margin-right: 10px;
        cursor: pointer;
        
        &:hover {
            color: $hover-color;
            transition: 0.3s;
        }
    }
}
