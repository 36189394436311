@use '../../styles/variables.scss' as *;
@use '../../styles/mixins.scss' as *;

.content {
    width: 430px;
    min-height: 396px;

    @include media-mobile() {
        width: 100%;
        height: auto;
    }

    .title {
        font-family: $font-family-roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.15;
        color: $dark-blue-color;

        @include media-mobile() {
            font-size: 18px;
        }
    }

    .subtitle {
        color: #bbb5aa;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.2;
        margin: 19px 0 45px;

        @include media-mobile() {
            font-size: 16px;
            margin: 12px 0 30px;
        }
    }

    $btn-color: $dark-blue-color;
    .form {
        display: flex;
        flex-direction: column;
        position: relative;

        input.form-input {
            border: 1px solid #bbb5aa;
            box-sizing: border-box;
            border-radius: 15px;
            width: 100%;
            padding: 11px 15px;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 1.5;
            outline: none;
        }
        
        .send-btn {
            border-radius: 15px;
            background-color: #fff;
            height: 100%;
            padding: 10px 44px;
            right: 0;
            border: 1px solid $btn-color;
            box-sizing: border-box;
            cursor: pointer;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: $btn-color;
            font-family: "Oswald";
            font-weight: 600;
            transition: .3s;
            position: relative;
            width: 100%;

            &:hover {
                background: $btn-color;
                color: #fff;
            }

            &:disabled {
                background: lighten($color: $btn-color, $amount: 50%);
                color: #fff;
                cursor: default;
                pointer-events: none;
            }

            .loading-indicator {
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
            }
        }
    }

    .pdf-btn {
        display: flex;
        flex-direction: row;
        outline: none;
        border: none;
        background: none;
        display: flex;
        align-items: center;
        margin-top: 22px;
        cursor: pointer;
        transition: .3s;
        position: relative;

        $main-color: #040503;
        $hover-color: $btn-color;

        .pdf-btn-text {
            margin-left: 16px;
            font-size: 20px;
            -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
            color: $main-color;
            transition: .3s;
        }

        .pdf-icon {
            > svg {
                fill: $main-color;
            }
        }

        &:hover {
            .pdf-btn-text {
                color: $hover-color;
                transition: .3s;
            }

            .pdf-icon {
                > svg {
                    fill: $hover-color;
                }
            }
        }
    }
}