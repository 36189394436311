@use '../../styles/mixins.scss' as *;

$products-per-row: 5;
$product-width: 340px;
$product-height: 208px;
$products-gap: 20px;

.stores-container {
    max-width: 400px;
    
    @include media-desktop() {
        width: 400px;
        margin: 40px auto 0 auto;
    }

    @include media-tablet() {
        min-width: 300px;
        margin: 30px auto 0 auto;
    }

    @include media-mobile() {
        width: 100%;
        margin: 20px auto 0 auto;
    }
}

.products-container {
    max-width: calc($products-per-row * ($product-width + $products-gap) - $products-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: $products-gap;
    margin: 0 auto;
    padding: 100px;
    position: relative;
    //margin-top: 50%;
    //transform: translateY(-50%);
}

.products-container > * {
    width: $product-width;
}