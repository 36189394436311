@use '../variables' as *;

.ant-form-item-control-input {
    .ant-input {
        &:not(&:focus-within):hover {
            &::placeholder {
                color: $hover-color;
            }
        }

        &.ant-input-sm {
            font-size: $form-input-font-size-sm + px;
        }
    }

    &:focus-within {
        .ant-input-affix-wrapper {
            color: $form-input-color;
            border-color: $form-input-color;
        }
    }
}