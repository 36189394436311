@use '../variables.scss' as *;

.ant-radio-group {
    .ant-radio-wrapper {
        span {
            font-size: $radios-font-size + px;
        }
    }
}

.ant-form-item-has-error {
    .ant-radio {
        color: $form-error-color;

        &.ant-radio-checked {
            .ant-radio-inner {
                border-color: $form-error-color;
                background-color: $form-error-color;
            }
        }
    }
}