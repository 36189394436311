//@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&family=Roboto:wght@400;500;700&display=swap');

$futura-medium: "~/public/fonts/futura-pt-medium";
$futura-bold: "~/public/fonts/futura-pt-bold";

@font-face {
    font-family: "Futura PT";
    src: url(#{$futura-medium}.woff2) format("woff2"),
    url(#{$futura-medium}.woff) format("woff"), url(#{$futura-medium}.ttf) format("truetype");
    font-weight: 450;
    font-style: normal;
}

@font-face {
    font-family: "Futura PT";
    src: url(#{$futura-bold}.woff2) format("woff2"),
    url(#{$futura-bold}.woff) format("woff"), url(#{$futura-bold}.ttf) format("truetype");
    font-weight: 600;
    font-style: normal;
}