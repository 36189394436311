@use 'sass:math';
@use 'sass:color';

@use '../../styles/variables' as *;
@use '../../styles/mixins' as *;

$icon-size: 20px;
$icon-size-sm: 16px;
$icon-text-size: 15px;
$icon-text-size-sm: 12px;
$icon-text-size-m: 14px;
$icon-border-radius: 6px;
$icon-border-radius-sm: 4px;
$item-width: 240px;
$separator-height: 30px;
$separator-height-sm: 24px;

.menu-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 10px;
    margin: 6px 0;
    text-align: center;
    box-sizing: border-box;
    color: $inactive-color;
    font-weight: bold;
    width: $item-width;
    white-space: nowrap;

    @include media-mobile() {
        font-size: $icon-text-size-sm;
        max-width: $item-width;
        width: fit-content;
    }
    @include media-mobile() {
        font-size: $icon-text-size-m;
        max-width: $item-width;
        width: fit-content;
    }
    
    .icon {
        width: $icon-size;
        height: $icon-size;
        line-height: $icon-size;
        border-radius: $icon-border-radius;

        @include media-mobile() {
            width: $icon-size-sm;
            height: $icon-size-sm;
            line-height: $icon-size-sm;
            border-radius: $icon-border-radius-sm;
        }
        @include media-tablet() {
            width: $icon-size-sm;
            height: $icon-size-sm;
            line-height: $icon-size-sm;
            border-radius: $icon-border-radius-sm;
        }

        color: white;
        background-color: $inactive-color;
        margin-right: 10px;

        font-family: $font-family-roboto;
        font-weight: 500;
        font-size: $icon-text-size;

        @include media-mobile() {
            font-size: $icon-text-size-sm;
        }
        @include media-tablet() {
            font-size: $icon-text-size-sm;
        }
    }

    .separator {
        height: $separator-height;
        @include media-mobile() {
            height: $separator-height-sm;
        }
        width: 2px;
        background-color: $primary-section-color;
        position: absolute;
        right: 0;
    }


    &.active {
        color: $dark-blue-color;

        .icon {
            background-color: $branding-color;
        }
    }

    &:last-child {        
        .separator {
            display: none;
        }
    }
}
