@use 'sass:math';
@use 'sass:color';

$app-padding: 1.5em;
$dark-brown-color: #6a6a63;
$dark-blue-color: #425D76;
$light-blue-color: color.scale($dark-blue-color, $lightness: 30%);
$header-height: 60px;
$header-height-sm: 50px;
$header-height-m: 50px;

$text-size: 16px;

$mobile-breakpoint: 756px;
$tablet-breakpoint: 992px;
$branding-secondary-accent-color: #f9f9f9;

$font-family-futura: 'Futura PT';
$font-family-oswald: 'Oswald', sans-serif;
$font-family-roboto: 'Roboto', sans-serif;

$text-color: #6a6a63;
$branding-color: #B77250;
$inactive-color: #BCBCBC;
$error-color: #DE5555;
$hover-color: #D1B888;

/** other **/
$primary-section-color: #EDEAE2;
$secondary-section-color: #F9F9F9;
$font-size-header: 16;
$font-size-text: 14;

/** buttons **/
$button-background-color: #6A6A63;
$button-hover-background-color: $hover-color;
$button-border-color: $button-background-color;
$button-text-color: #ffffff;
$button-text-hover-color: #ffffff;
$button-letter-spacing: 1;
$button-text-transform: uppercase;
$button-height: 50;
$button-height-sm: 27;
$button-disabled-color: #BBB5AA;
$button-disabled-text-color: #ffffff;
$button-border-radius: 30;
$button-border-radius-sm: 30;
$button-font-size: 18;
$button-font-size-sm: 10;
$button-font-size-m: 14;
$button-line-height: math.div($button-height, $button-font-size);
$button-line-height-sm: math.div($button-height-sm, $button-font-size-sm);
$button-font-family: $font-family-roboto;
$button-color-disabled: #BBB5AA;
$button-padding-horizontal: 30;
$button-padding-horizontal-sm: 15;

/** steps **/
$steps-height: 40;
$steps-height-sm: 25;
$steps-background: $branding-color;
$steps-font-size: 16;
$steps-font-size-sm: 12;
$steps-description-font-size: 14;
$steps-description-font-size-sm: 12;
$steps-description-line-height-sm: 14;
$steps-font-family: $font-family-roboto;
$steps-line-color: #EDEAE2;
$steps-icon-color: #BCBCBC;
$steps-icon-text-color: #ffffff;
$steps-description-color: #425D76;
$steps-description-inactive-color: #BCBCBC;
$steps-separator-length: 50;

/** forms **/
$form-font-family: $font-family-roboto;
$form-font-size: 16;
$form-font-size-sm: 14;
$form-text-color: #6A6A63;
$form-line-height: 24;
$form-line-height-sm: 24;
$form-label-height: 42;
$form-label-height-sm: 24;
$form-label-line-height: 19;
$form-label-line-height-sm: 24;
$form-label-color: $form-text-color;
$form-required-color: $branding-color;
$form-input-border-color: #DEDEDE;
$form-input-border-radius: 15;
$form-input-border-radius-sm: 15;
$form-input-height: 42;
$form-input-height-sm: 42;
$form-input-font-size: 15;
$form-input-font-size-sm: 13;
$form-input-padding-horizontal-sm: 16;
$form-input-line-height: $form-line-height;
$form-input-line-height-sm: $form-line-height-sm;
$form-input-placeholder-color: #BCBCBC;
$form-input-color: $form-text-color;
$form-margin-bottom: 12;
$form-margin-bottom-sm: 12;
$form-error-color: $error-color;

/** switch **/
$switch-off-color: #E0E2E6;
$switch-on-color: #425D76;
$switch-height: 24;
$switch-height-sm: 24;

/** radios **/
$radios-color: $branding-color;
$radios-font-size: 14;
$radios-font-family: $font-family-roboto;

/** sections **/
$section-title-color: $branding-color;