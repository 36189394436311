@use '../../styles/variables' as *;
@use '../../styles/mixins' as *;
@use 'sass:color';

.summary-page-content {
    display: grid;
    grid-template-areas: 
        'pricing'
        'form';
    row-gap: 14px;

    .summary-pricing-block {
        grid-area: pricing;

        margin-bottom: 14px;
        order: 2;
        margin: 0 auto;

        @include media-desktop() {
            min-width: 400px;
        }

        @include media-mobile() {
            margin: 0 20px;
            margin-top: 30px;
            width: 100%;
            margin: 0 auto;
            max-width: 400px;
        }

        @include media-tablet() {
            margin: 0 40px;
            margin-top: 30px;
            width: 100%;
            margin: 0 auto;
            max-width: 400px;
        }
    }

    .summary-form {
        grid-area: form;
        display: grid;
        grid-auto-rows: max-content;
        grid-template-columns: auto;

        @include media-mobile() {
            row-gap: 30px;
        }

        @include media-tablet() {
            row-gap: 30px;
        }

        @include media-desktop() {
            row-gap: 40px;
        }

        .purchase-opt {
            margin: 0 auto;

            @mixin purchase-opt-fix() {
                width: 100%;

                :global { 
                    .ant-radio-group {
                        &.ant-radio-group-small {
                            flex-direction: row;
                        }
                    }

                    .ant-form-item-control {
                        flex: 1 !important;
                        .ant-radio-group {justify-content: center}
                    }
                }

                @content;
            }
            
            @include media-mobile() {
                @include purchase-opt-fix();    
            }

            @include media-tablet() {
                @include purchase-opt-fix();    
            }

            :global .ant-form-item-label {
                text-align: center;
                padding-bottom: 10px;
                justify-content: center;

                @include media-mobile() {
                    
                }
                
                @include media-mobile() {
                    
                }
                
                label {
                    height: $form-line-height + px;
                    font-weight: 500;
                }
            }

            :global .ant-form-item-control-input-content {
                justify-content: center !important;
            }
        }

        .summary-form-content {
            display: grid;
            grid-auto-rows: auto;
            grid-auto-flow: column;
            justify-content: center;

            @include media-mobile() {
                padding: 20px 20px 0 20px;
                grid-template-columns: 100%;
                grid-auto-flow: row;
            }

            @include media-tablet() {
                padding: 0 80px;
                grid-auto-flow: row;
                column-gap: 40px;
                grid-template-columns: 100%;
            }

            @include media-desktop() {
                column-gap: 80px;
                grid-auto-columns: 450px;
            }
        }

        .summary-form-footer {
            display: grid;
            grid-auto-rows: auto;
            grid-auto-flow: column;
            justify-content: center;
            
            width: fit-content;
            margin: 0 auto;
            padding: 20px 40px;
            border-radius: 15px;

            background-color: $branding-secondary-accent-color;
            
            @include media-mobile() {
                padding: 20px 20px 0 20px;
                grid-template-columns: 100%;
                grid-auto-flow: row;
            }

            @include media-tablet() {
                column-gap: 40px;
                row-gap: 20px;
                grid-template-columns: 50%;

                > * {
                    grid-row: 2;
                }

                > *:first-child {
                    grid-row: 1;
                    grid-column: 1/3;
                }    
            }

            @include media-desktop() {
                column-gap: 80px;
                grid-auto-columns: 450px;
            }
        }
    }
}

.summary-pricing-block-title {
    button {
        border: none !important;
        padding: 0 2px !important;
        vertical-align: middle;
        color: $text-color;
        font-weight: 600;
        vertical-align: middle;

        &:hover {
            color: lighten($text-color, 15) !important;
        }
    }
}

.pricing-modal {
    padding-top: 55px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 10px !important;
    overflow: hidden;
}