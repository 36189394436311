@use '../variables.scss' as *;
@use '../mixins.scss' as *;

.ant-btn {
    &-default:not(:disabled) {
        border: 1px solid $button-border-color;
    }
    text-transform: uppercase;
    box-shadow: none;
    font-weight: 700;

    &:not(a) {
        line-height: $button-line-height + px;
    }
    
    font-family: $button-font-family;
    letter-spacing: $button-letter-spacing + px;

    &.ant-btn-sm {
        font-size: $button-font-size-sm + px;
        padding-left: $button-padding-horizontal-sm + px;
        padding-right: $button-padding-horizontal-sm + px;
    }

    @include media-tablet() {
        font-size: $button-font-size-m + px;
    }

    &-primary {
        &:not(:disabled) {
            &:hover,
            &:active {
                color: $button-text-hover-color;
            }
        }
    }

    &-default {
        &:not(:disabled) {
            &:hover,
            &:active {
                //background-color: $button-background-color;
                color: $button-hover-background-color;
                border-color: $button-hover-background-color;
            }
        }
    }

    > span {
        font-family: inherit;
        font-size: inherit;
        letter-spacing: inherit;
    }
}
