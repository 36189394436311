@use '../../styles/variables.scss' as *;
@use '../../styles/mixins.scss' as *;

.review {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1 1 100%;
    height: 100%;
    width: 100%;
    justify-content: space-between;

    > .review-col {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        box-sizing: border-box;

        @include media-tablet() {
            flex-direction: row;
            justify-content: space-between;
        }

        .section {
            display: flex;
            flex-direction: column;
            &:not(&:last-child) {
                margin-bottom: 60px;

                @include media-mobile() {
                    margin-bottom: 12px;
                }

                @include media-tablet() {
                    margin-bottom: 14px;
                }
            }

            @include media-tablet() {
                flex: 1 1 50%;
                &:not(&:last-child) {
                    margin-right: 60px;
                }
            }

            .section-title {
                font-family: $font-family-roboto;
                color: $branding-color;
                font-weight: 500;
                font-size: 16px;
                line-height: 130%;
                margin-bottom: 10px;
            }

            .row {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                line-height: 130%;
                
                &:not(&:last-child) {
                    margin-bottom: 10px;
                }
                
                > .col {
                    font-family: $font-family-roboto;
                    font-weight: 400;
                    font-size: 14px;

                    &:first-child {
                        
                    }

                    &:last-child {
                        
                    }
                }
            }
        }
    }
}
