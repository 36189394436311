@use '../../styles/variables.scss' as *;
@use '../../styles/mixins.scss' as *;

.customer-page-content {
    display: grid;
    grid-template-areas: 
        'steps'
        'content';
    grid-auto-rows: max-content;
    place-content: center;
    min-height: 100%;
    
    @include media-mobile() {
        padding-left: 34px;
        padding-right: 34px;

        grid-template-columns: minmax(min-content, 100%);
    }

    @include media-tablet() {
        grid-auto-columns: minmax(min-content, 500px);
        > form {
            padding: 0 50px;
        }
    }

    @include media-desktop() {
        grid-template-columns: 485px;
    }

    .steps {
        grid-area: steps;

        @include media-mobile() {
        }

        @include media-tablet() {
        }
    }

    .content {
        grid-area: content;

        @include media-mobile() {
            align-items: start;
        }
        @include media-tablet() {
            align-items: center;
        }
        @include media-desktop() {
            align-items: center;
        }
    }
}
