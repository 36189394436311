@use '../../../styles/variables.scss' as *;
@use '../../../styles/mixins.scss' as *;

.page {
    position: relative;
    display: grid;
    grid-auto-rows: min-content auto;
    
    grid-template-areas: 
        'nav'
        'content';
    column-gap: 0;
    row-gap: 30px;
    grid-auto-flow: dense;
    min-width: 100%;

    @include media-mobile() {
        grid-template-columns: 100%;
    }

    @include media-tablet() {
        grid-template-columns: 100%;
    }

    @include media-desktop() {
        grid-template-columns: auto;
    }

    .page-navigation {
        grid-area: nav;
        display: flex;
        overflow-x: auto;
        width: 100%;

        > * {
            &:first-child {
                margin-left: auto;
            }
        }
        
        @include media-mobile() {
            padding: 6px 12px;
            column-gap: 15px;

            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
        }

        @include media-tablet() {
            padding: 9px 41px;
            column-gap: 15px;

            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
        }

        @include media-desktop() {
            padding: 12px 54px;
            column-gap: 20px;
        }
    }

    .page-content {
        grid-area: content;

        @include media-mobile() {
            padding: 0 15px 15px 15px;
        }

        @include media-tablet() {
            padding: 0 40px 40px 40px;
        }

        @include media-desktop() {
            padding: 0 40px 40px 40px;
        }
    }
}