@use '../../styles/variables.scss' as *;
@use '../../styles/mixins' as *;

.app-header {
    // display: flex;
    // flex: 0 0 auto;
    height: $header-height;
    box-shadow: 0 12px 9px -9px #f1f1f1;
    overflow-x: auto;
    
    @include media-mobile() {
        height: $header-height-sm;
    }

    @include media-tablet() {
        height: $header-height-m;
    }
}

.app-container {
    position: relative;
    height: calc(100% - $header-height);
    overflow: auto;

    @include media-mobile() {
        height: calc(100% - $header-height-sm);
    }

    @include media-tablet() {
        height: calc(100% - $header-height-m);
    }
}

.app-container-no-header {
    position: relative;
    height: 100%;
    overflow: auto;
}