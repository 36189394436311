@use '../../styles/variables.scss' as *;
@use '../../styles/mixins.scss' as *;

.autocomplete {
    width: 450px;
    margin-bottom: 30px;
}

.buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    
    @include media-mobile() {
        flex-direction: column;
        gap: 10px;
    }

    > * {
        margin-left: 16px;
    }
}

.title {
    color: black;
    font-family: $font-family-roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 10px;
}

.g-map-container,
.g-map-container-fixed {
    width: 100%;
    height: 440px;

    @include media-mobile() {
        width: 100%;
        height: 300px;
    }

    @include media-tablet() {
        height: 400px;
    }
}