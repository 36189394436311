@use 'sass:math';
@use '../../../styles/variables.scss' as *;
@use '../../../styles/mixins.scss' as *;

.modal-wrapper {
    @media screen {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    @media print {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    
    z-index: 10000;

    &::before {
        background: #bdbdbd3d;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        @media print {
            display: none;
        }
    }

    .modal-background {
        @media screen {
            align-items: center;
            backdrop-filter: blur(6px);
            display: flex;
            height: 100vh;
            justify-content: center;
            left: 0;
            position: absolute;
            top: 0;
            width: 100vw;
        }

        .modal-body {
            $padding-top: 66px;

            @media screen {
                background: white;
                box-shadow: 0 4px 15px rgb(0 0 0 / 15%);
                border-radius: 20px;
                position: relative;
                min-width: 200px;
                min-height: 100px;

                padding: $padding-top 62px 26px 63px;

                max-height: 100%;
                overflow-y: auto;
            }
            
            @include media-mobile() {
                padding: 20px;
            }

            @include media-tablet() {
                padding: 20px;
            }
            
            .close-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                position: absolute;
                top: math.div($padding-top, 2);
                right: math.div($padding-top, 2);;
                transform: translate(50%, -50%);
                cursor: pointer;

                > svg {
                    $svg-color:#C7C8CC;
                    fill: $svg-color;

                    &:hover {
                        fill: darken($color: $svg-color, $amount: 30%);
                        transition: all 0.1s linear;
                    }
                }
            }
        }
    }
}