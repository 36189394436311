@use '../../../styles/variables.scss' as *;
@use '../../../styles/mixins.scss' as *;

/** sections **/
$secction-title-font-size: 16px;
$secction-title-font-size-tablet: 16px;
$secction-title-font-size-mobile: 14px;

$secction-title-font-weight: 500;
$secction-title-font-family: $font-family-roboto;
$secction-title-line-height: 130%;
$border-radius: 15px;

.section {
    background-color: transparent;

    &-round-borders {
        border-radius: 15px;
    }

    &-primary {
        background-color: $primary-section-color;
    }

    &-secondary {
        background-color: $secondary-section-color;
    }

    &-default {
        background-color: transparent;
    }

    &-padding-size-small {
        @include media-mobile() {
            padding: 12px 14px;
        }

        @include media-tablet() {
            padding: 12px 14px;
        }
        
        @include media-desktop() {
            padding: 12px 20px;
        }
    }

    &-padding-size-big {
        @include media-mobile() {
            padding: 20px 20px;
        }

        @include media-tablet() {
            padding: 20px 20px;
        }
        
        @include media-desktop() {
            padding: 40px 40px;
        }
    }

    @include media-mobile() {

    }

    @include media-tablet() {
        
    }

    @include media-desktop() {
        
    }

    .section-title {
        color: $branding-color;
        font-weight: $secction-title-font-weight;
        font-family: $secction-title-font-family;
        line-height: $secction-title-line-height;
        margin-bottom: 11px;

        &-align-start {
            text-align: start;
        }

        &-align-center {
            text-align: center;
        }

        &-align-end {
            text-align: end;
        }

        @include media-mobile() {
            font-size: $secction-title-font-size-mobile;

            &-size-bigger {
                font-size: $secction-title-font-size-mobile + 2px;
            }
        }

        @include media-tablet() {
            font-size: $secction-title-font-size-tablet;

            &-size-bigger {
                font-size: $secction-title-font-size-tablet + 2px;
            }
        }

        @include media-desktop() {
            font-size: $secction-title-font-size;

            &-size-bigger {
                font-size: $secction-title-font-size + 2px;
            }
        }
    }

    .section-content {
        @include media-mobile() {

        }

        @include media-tablet() {
            
        }

        @include media-desktop() {
            
        }
    }
}