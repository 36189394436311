@use "sass:math";
@use './variables.scss' as *;

@mixin margin-gap($margin) {
    $half: math.div($margin, 2);

    & {
        &:not(:last-child) {
            margin-right: $half;
        }

        &:not(:first-child) {
            margin-left: $half;
        }
    }
}

@mixin padding-gap($margin) {
    $half: math.div($margin, 2);

    & {
        &:not(:last-child) {
            padding-right: $half;
        }

        &:not(:first-child) {
            padding-left: $half;
        }
    }
}

@mixin media-between($min, $max) {
    @media screen and (min-width: $min) and (max-width: $max) {
        @content;
    }
}

@mixin media-min($min) {
    @media screen and (min-width: $min) {
        @content;
    }
}

@mixin media-max($max) {
    @media screen and (max-width: $max) {
        @content;
    }
}

@mixin media-mobile() {
    @include media-max($mobile-breakpoint) {
        @content;
    }
}

@mixin media-tablet() {
    @include media-between($mobile-breakpoint + 1px, $tablet-breakpoint) {
        @content;
    }
}

@mixin media-desktop() {
    @include media-min($tablet-breakpoint + 1px) {
        @content;
    }
}