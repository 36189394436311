.skeleton-row {
        display: flex;
        width: 100%;
        margin-bottom: 24px;

        .skeleton-btn {
            margin-right: 15px;
        }

        :global {
            .ant-skeleton {
                //height: $control-height;

                .ant-skeleton-header {
                    line-height: 100%;
                    
                    .ant-skeleton-avatar-sm {
                        width: 16px;
                        height: 16px;
                        line-height: 16px;
                    }
                }

                &.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
                    margin: 0;
                }
            }
        }
    }