@use '../variables.scss' as *;
@use '../mixins.scss' as *;

.ant-steps {
    .ant-steps-item {
        .ant-steps-item-container {
            .ant-steps-item-tail {
                $padding-horizontal: 34px;
                padding-left: $padding-horizontal;
                padding-right: $padding-horizontal;
                
                &::after {
                    background-color: $steps-line-color;
                }
            }

            .ant-steps-item-content {
                .ant-steps-item-title {
                    font-weight: 450;
                    font-size: $steps-description-font-size + px;

                    @include media-mobile() {
                        font-size: $steps-description-font-size-sm + px;
                        line-height: $steps-description-line-height-sm + px;
                    }
                }
            }

            .ant-steps-item-icon {
                @include media-mobile() {
                    width: $steps-height-sm + px;
                    height: $steps-height-sm + px;
                    font-size: $steps-font-size-sm + px;
                    line-height: $steps-height-sm + px;
                }
            }
        }
    }

    &.ant-steps-small {
        .ant-steps-item-title {
            font-size: $steps-description-font-size-sm + px;
            line-height: $steps-description-line-height-sm + px;
        }

        .ant-steps-item-icon {
            width: $steps-height-sm + px;
            height: $steps-height-sm + px;
            font-size: $steps-font-size-sm + px;
        }
    }
}